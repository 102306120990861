import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

import TrainingBG from "../images/training/BACKGGROUND1-01.png";
import Banner from "../images/training/Modules/Module02/img01.jpeg";

import img01 from "../images/training/Modules/Module02/img02.jpg";
import img02 from "../images/training/Modules/Module02/img03.jpg";

const Section = styled.section`
  padding: 60px 0;
`
const BannerSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 50vh;
  .bannerText{
    flex-basis: 55%;
    background-color: #2a9e9b;
    display: flex;
    align-items: center;
    justify-content: end;
    .textArea{
      margin: 40px;
      color: #fff;
    }
  }
  .bannerImage{
    flex-basis: 45%;
    background-image: url(${Banner});
    background-size: cover;
    background-position: 100% 50%;
    background-color: #0f0f0f;
    height: 100%;
  }
`
const BenefitBox = styled.div`

`
const ModuleBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  .text{
    flex-basis: 50%;
    p{
      font-weight: bold;
    }
  }
  .image{
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    img{
      height: 300px;
      object-fit: cover;
      object-position: 50% 35%;
    }
  }
`
const ReturnButton = styled.div`
  text-align: center;
  a{
    border: 1px solid #0f0f0f;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
    background-color: #00c3f3;
    :hover{
      background-color: #0f0f0f;
      border-color: #00c3f3;
      color: #fff;
    }
  }
  `


const TrainingDesignPage = () => (
  <Layout>
    <SEO title="3D Design (Advance) Training" description="3D Design (Advance) Training" />
    <BannerSection>
      <div className="bannerText">
        <div className="textArea">
          <h3>
            3D Design ( ADVANCED ) Training
          </h3>
          <p>
            Unlock the secret of designing for additive manufacturing with our advanced course! <br/>
            Discover the rules and restriction of AM Design and gain expertise in creating flawless prints. <br/>
            Minimize printing errors, slash costs, and optimize parts efficiency with the essential knowledge <br/>
            acquired in this training program.
          </p>
        </div>
      </div>
      <div className="bannerImage"/>
    </BannerSection>
    <div style={{backgroundImage:`url(${TrainingBG})`, backgroundRepeat:"repeat"}}>
      <Container>
        <Section>
          <BenefitBox>
            <h3>
              Your Benefits
            </h3>
            <ul>
              <li>
                Learn how to successfully design for add manufacturing (AM)
              </li>
              <li>
                Hands-on experience of the complete AM Design Workflow
              </li>
              <li>
                Fundamentals of the AM process to understand AM design thinking
              </li>
            </ul>
          </BenefitBox>
        </Section>
        <Section>
          <h3>
            Module Content
          </h3>
          <ModuleBox>
            <div className="text">
              <div>
                <p>
                  Unit 1: Introduction to 3D Design
                </p>
                <ul>
                  <li>
                    In-depth learning of 3D Design Software
                  </li>
                  <li>
                    Fundamentals features for 3D Design
                  </li>
                  <li>
                    Hands-on practise sessions focusing on design thinking for AM
                  </li>
                </ul>
                <br/>
              </div>

              <div>
                <p>
                  Unit 2: Reverse Engineering
                </p>
                <ul>
                  <li>
                    Hands-on 3D Scanner
                  </li>
                  <li>
                    3D Data Acqusition and Data Manipulation
                  </li>
                </ul>
                <br/>
              </div>

            </div>
            <div className="image">
              <img src={img01} />
              <img src={img02} />
            </div>
          </ModuleBox>

        </Section>
        <Section>
          <ReturnButton>
            <a href='/training/#iamqs'> Return to Page </a>
          </ReturnButton>
        </Section>
      </Container>

    </div>
  </Layout>
)

export default TrainingDesignPage
